import React from 'react';
import { useSpring, animated } from "react-spring";

function CardInfo(props) {

    const style = useSpring({ opacity: 1, from: { opacity: 0 } });

    return (
        <animated.div className="m-card-info" style={style}>
            <a href={props.link} target="_blank" rel="noopener noreferrer">
                <p className="m-card-title">
                    {props.title}
                </p>
            </a>
        </animated.div>
    );
}

export default CardInfo;