import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import background from '../assets/myFace1.jpg'

var backgroundImageStyle = {
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(${background})`
};

var centerContainer = {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
}

function PageNotFound() {
    return (
        <div style={backgroundImageStyle}>
            <Container style={centerContainer}>
                <Row className="justify-content-center">
                    <h1 className="display-1 font-weight-bolder text-danger">404</h1>
                </Row>
                <Row className="justify-content-center">
                    <h2 className="text-danger">Opps, that page can't be found...</h2>
                </Row>
                <Row className="justify-content-center">
                    <Link to="/">back to home</Link>
                </Row>
            </Container>
        </div>
    );
}

export default PageNotFound;