import React from 'react';
import emailjs from 'emailjs-com';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Hero from '../components/Hero';
import Content from '../components/Content';
require('dotenv').config();

class ContactPage extends React.Component {

    serviceID = process.env.REACT_APP_SERVICE_ID
    processID = process.env.REACT_APP_TEMPLATE_ID
    userID = process.env.REACT_APP_USER_ID

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: false,
            emailSent: null
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    }

    sendEmail = (event) => {
        this.setState({
            disabled: true
        });

        emailjs.sendForm(this.serviceID, this.processID, event.target, this.userID)
            .then((result) => {
                console.log(event.target);
                console.log(result.text);
                if (result.status === 200) {
                    this.setState({
                        disabled: false,
                        emailSent: true
                    });
                } else {
                    this.setState({
                        disabled: false,
                        emailSent: false
                    });
                }
            }, (error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    emailSent: false
                });
            });

        event.preventDefault();

        this.setState({
            name: '',
            email: '',
            message: '',
            sentEmail: null
        });
    }

    render() {
        return (
            <div>
                <Hero title={this.props.title} />
                <Content >
                    <Form id="form-id" onSubmit={this.sendEmail}>

                        <Form.Group>
                            <Form.Label htmlFor="name">name</Form.Label>
                            <Form.Control id="name" name="name" type="text" value={this.state.name} onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="email">email</Form.Label>
                            <Form.Control id="email" name="email" type="email" value={this.state.email} onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="message">message</Form.Label>
                            <Form.Control id="message" name="message" as="textarea" rows="3" value={this.state.message} onChange={this.handleChange} />
                        </Form.Group>

                        <Button className="d-inline-block" variant="primary" type="submit">Send</Button>

                        {this.state.emailSent === true && <p className="d-inline success-message">email sent</p>}
                        {this.state.emailSent === false && <p className="d-inline error-message">error sending email</p>}
                    </Form>
                </Content>
            </div>
        );
    }
}

export default ContactPage;
