import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import construction from '../assets/construction.jpg';
import code from '../assets/code.png';
import Content from '../components/Content';
import Hero from '../components/Hero';

function AboutPage(props) {
    return (
        <div>
            <Hero title={props.title} />
            <Content>
                <Container fluid={true}>
                    <Row className="bottom-padding">
                        <Col xs={true} md={8}>
                            <p>
                                Hey there, thanks for stopping by to check out my site. Before getting into software development I spend the majority of my career in the construction industry. I started in residential remodeling and eventually joined a union to become a journeyman pipefitter. I learned an incredible amount about the construction process from residential foundations to completing large commercial and industrial projects. Many transferable skills were learned along the way with a firm foundation in project structure and organization. Additionally, there were a lot of soft skills picked up along the way too which apply to both industries.
                            </p>
                            <p>
                                How did I get from construction to software development though? Well, work in the construction industry fluctuates leading to time off when the industry slows for any reason. During one such time I found an opportunity to contract with Expedia as a user advocate for their mobile app team. It was during this time that I fell in love with the world of software development and the idea of building things in the virtual world. Thus began my journey…
                            </p>
                        </Col>
                        <Col className="d-none d-sm-block justify-content-space-between" md={4}>
                            <img className="m-about-image" src={construction} alt="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-none d-sm-block" s={2} md={4}>
                            <img className="m-about-image" src={code} alt="" />
                        </Col>
                        <Col xs={true} s={6} md={8}>
                            <p>
                                Before I even completed my contract I enrolled in a Java certification program and began to see the parallels between what I learned in the building trades to what I was learning about programming. Every good projects starts with planning and a firm foundation in each field. As I returned to work as a pipe fitter, I continued to learn more about software development in my free time. Eventually, I had another slow period and was able to enroll in a coding bootcamp (shout out to Grand Circus, Detroit!). I had such a great time learning and building new things that I started applying for position directly after completing the bootcamp. Fortunately, I was able to secure a position were my fascination with programming originated -- as an iOS engineer in test at Expedia!
                            </p>
                            <p>
                                After a couple months in my role I quickly evelated to the role of iOS engineer I and began working on customer facing UIs and, of course, bug fixes. I learned a great deal about A/B testing, unit testing, UI testing, MVVM, clean code, accessibility, unified design systems, CI/CD, the agile process, and so much more. I feel like I’ve found my passion in a career that better aligns with my personality and gives me a greater sense of pride in my accomplishments. It’s from here that I look forward to building beautiful yet functional applications from web to mobile or wherever the code takes me.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Content>
        </div>
    );
}

export default AboutPage;