import React from 'react';

function WebApps(props) {
    return (
        <div>
            <p>Hello</p>
        </div>
    );
}

export default WebApps;