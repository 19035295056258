import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import './App.css';

import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import WebApps from './pages/WebApps';
import PageNotFound from './pages/PageNotFound';
import Header from './components/Header';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: 'Matthew Kruk',
      headerLinks: [
        { title: 'home', path: '/' },
        { title: 'about', path: '/about' },
        { title: 'contact', path: '/contact' }
      ],
      home: {
        title: 'Build beautiful things',
        subtitle: 'our creations define our nature',
        text: 'Checkout some things I\'ve worked on:'
      },
      about: {
        title: 'about me'
      },
      contact: {
        title: 'let\'s chat'
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Container className="p-0" fluid={true}>
          <Header />

          <Switch>
            <Route path="/" exact render={() => <HomePage title={this.state.home.title} subtitle={this.state.home.subtitle} text={this.state.home.text} />} />
            <Route path="/about" render={() => <AboutPage title={this.state.about.title} />} />
            <Route path="/contact" render={() => <ContactPage title={this.state.contact.title} />} />
            <Route path="/webapps" component={WebApps} />
            <Route component={PageNotFound} />
          </Switch>

          <Footer />
        </Container>
      </BrowserRouter>
    );
  }
}

export default App;
