import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <Navbar className="border-bottom" background="tranparent" expand="lg">
      <Navbar.Brand>Matthew Kruk</Navbar.Brand>
      <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
      <Navbar.Collapse id="navbar-toggle">
        <Nav className="ml-auto">
          <Link className="nav-link" to="/">home</Link>
          <Link className="nav-link" to="/about">about</Link>
          <Link className="nav-link" to="/contact">contact</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;